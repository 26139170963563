<template>
  <div class="content"  style="background: #f6f7fb;">
    <template v-if="!detailVisible && !addVisible">
      <div class='card' style='display: flex;'>
        <div class="left" style="flex-shrink: 0; display: flex;">
          <!-- <el-button type='primary' @click='dialogVisible = true' icon='el-icon-plus'>新增退款</el-button> -->
          <!-- <el-button type='primary' @click='pickUpVisible = true'>提单</el-button> -->
          <!-- <el-button
            type='primary'
            plain
            @click='handleExport'
            icon='el-icon-upload2'
            :loading="loading3"
            style="margin-right: 10px;  height: 40px;"
          >
            导出
          </el-button> -->
          <!-- <LocalPrint :tableType="2" :show="true" :loading="printLoading" :printRows="printRows" :printData="printData" :title="title" :dialogVisible="localPrintVisible" @onClose="localPrintVisible = false" @onPrint="handlePrint" /> -->
          <!-- <el-button
            type='primary'
            plain
            icon='el-icon-printer'
            :loading="loading3"
          >
            打印
          </el-button> -->
        </div>
        <!-- <div class='line'></div> -->
        <div class="middle" style="flex: 1;">
          <Cond @onCond="handleCond" function_code="recycleList" @setCond="handleSetCond" />
        </div>
        <div class="right">
          <!-- <TableConf function_code="warehouse" @onRowList="handleRowList"/> -->
          <!-- <PrintConf function_code="recoveryPrint" @onRowList="handlePrintRow" /> -->
        </div>
      </div>
      <div class="total">
          <div class="total-right">
            <div class="total-right-item">回收总单数 <span>{{ tableData.total || '0'}}</span></div>
            <div class="total-right-item">回收总额 <span>￥{{ tableData.recycle_amount || '0.00'}}</span></div>
            <div class="total-right-item">回收数量 <span>{{ tableData.recycle_goods_pcs || '0'}}</span></div>
            <div class="total-right-item">总净金重 <span>{{ tableData.total_goldweight || '0'}}</span></div>
          </div>
        </div>
      <div class="table" v-loading="loading" style="border: 1px solid #ddd; border-radius: 6px; overflow: hidden;">
        <el-table
          @row-dblclick="handleDalclick"
          :data="tableData.list"
          style="width: 100%"
          height="660"
          border
          @selection-change="handleSelectionChange"
          :row-class-name="tableRowClassName"
        >
          <!-- <el-table-column
            type="selection"
            align="center"
            width="55">
          </el-table-column> -->
          <template v-for="item in rowList">
            <el-table-column
              :key="item.field_alias"
              :prop="item.field_alias"
              :label="item.field_text"
              align="center"
              :width="item.t_width">
              <template slot-scope="scope">
                <template v-if="item.field_alias !== 'goods' && item.field_alias !== 'member' && item.field_alias !== 'material_name' && item.field_alias !== 'goods_pcs' && item.field_alias !== 'goldweight'">
                  <span style="color: #F56C6C;" v-if="item.field_alias === 'refund_order_number'">
                    {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                  </span>
                  <span v-else>
                    {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                  </span>
                </template>
                <template v-if="item.field_alias === 'goods'">
                  <template v-for="(good, goodIndex) in scope.row.goods">
                    <div style="display: flex; padding: 10px;" :key="good.order_goods_id" :class="(goodIndex + 1) !== scope.row.goods.length? 'border': ''">
                      <div>
                        <el-image :src="good.goods_pic" :preview-src-list="[good.goods_pic]" style="width: 80px; height: 80px; object-fit: contain;" v-if="good.goods_pic" />
                        <img src="../../../../assets/images/nopic.png" style="width: 80px; height: 80px;" v-else />
                      </div>
                      <div style="margin-left: 15px; text-align: left;">
                        <div>品名：{{good.goods_name}}</div>
                        <div>品类：{{good.goods_type_name}}</div>
                        <div>货号：{{good.goods_number}}</div>
                        <!-- <div>
                          <el-tag v-if="scope.row.isRaw" type="warning">旧料</el-tag>
                        </div> -->
                        <div style="display: flex;">
                          <template v-for="item in good.goods_tag_pic">
                            <img :src="item" :key="item" style="margin-right: 5px;" />
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-if="item.field_alias === 'material_name'">
                  <template v-for="(good, goodIndex) in scope.row.material_name">
                    <div :key="goodIndex" :class="(goodIndex + 1) !== scope.row.goods.length? 'border': ''"
                    style="height: 107px; display: flex; justify-content: center; align-items: center;">
                      {{good}}
                    </div>
                  </template>
                </template>
                <template v-if="item.field_alias === 'goods_pcs'">
                  <template v-for="(good, goodIndex) in scope.row.goods_pcs">
                    <div :key="goodIndex" :class="(goodIndex + 1) !== scope.row.goods.length? 'border': ''"
                    style="height: 107px; display: flex; justify-content: center; align-items: center;">
                      {{good}}
                    </div>
                  </template>
                </template>
                <template v-if="item.field_alias === 'goldweight'">
                  <template v-for="(good, goodIndex) in scope.row.goldweight">
                    <div :key="goodIndex" :class="(goodIndex + 1) !== scope.row.goods.length? 'border': ''"
                    style="height: 107px; display: flex; justify-content: center; align-items: center;">
                      {{good}}
                    </div>
                  </template>
                </template>
                <template v-if="item.field_alias === 'member'">
                  <div>{{scope.row.member_name || '--'}}</div>
                  <div>{{scope.row.member_phone || '--'}}</div>
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            align='center'
            fixed='right'
            field_alias='operation'
            label='操作'
            width="180"
          >
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleChange($event, scope.row)"
                >
                  <el-option label="详情" :value="1"></el-option>
                  <el-option label="打印" :value="2"></el-option>
                  <el-option label="赎回" :value="3"></el-option>
                  <el-option label="解锁" :value="4" v-if="!noSockIds.includes(scope.row.recycle_order_id)"></el-option>
                  <el-option label="编辑" :value="5" v-if="noSockIds.includes(scope.row.recycle_order_id)"></el-option>
                  <el-option label="删除" :value="6" v-if="noSockIds.includes(scope.row.recycle_order_id)"></el-option>

                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class='pagina'>
          <el-pagination
            :current-page="page"
            background
            @size-change="handleSizeChange"
            layout='total, sizes, prev, pager, next, jumper'
            :total='tableData.total'
            :page-size='$store.state.pageSizes[0]'
            :page-sizes="$store.state.pageSizes"
            @current-change='handleCurrentChange'
          ></el-pagination>
      </div>
    </template>
    <el-dialog
      title="打印数据"
      :visible.sync="printVisible"
      width="55%"
      v-loading="infoLoading"
      :before-close="handleClose">
      <el-tabs v-model="printType">
        <el-tab-pane label="非自定义打印" name="noCustom"></el-tab-pane>
        <el-tab-pane label="自定义打印" name="custom"></el-tab-pane>
      </el-tabs>
      <template v-if="printType === 'noCustom'? true: false">
        <div class="btns">
          <el-button type="primary" v-print="printObj">本地打印</el-button>
          <el-button type="primary" plain @click="yunPrint">云打印</el-button>
        </div>
        <div id="printTest">
        <div class="title">{{printData.hierarchy_name}}</div>
        <div class="list">
          <div class="item">
            <div class="left">分店电话：</div>
            <div class="right">{{printData.shop_phone}}</div>
          </div>
          <div class="item">
            <div class="left">回收单号：</div>
            <div class="right">{{printData.recycle_order_number}}</div>
          </div>
          <div class="item">
            <div class="left">分店地址：</div>
            <div class="right">{{printData.shop_address}}</div>
          </div>
          <div class="item">
            <div class="left">日期：</div>
            <div class="right">{{printData.create_time}}</div>
          </div>
          <div class="item">
            <div class="left">销售顾问：</div>
            <div class="right">{{printData.main_store_name}} {{printData.vice_store_name}}</div>
          </div>
          <div class="item">
            <div class="left">备注：</div>
            <div class="right">{{printData.remark || '--'}}</div>
          </div>
        </div>
        <div class="my-table"  v-if="printData1.length">
          <div class="table-header flex">
            <div style="flex: 2;">货号</div>
            <div style="flex: 2;">品名</div>
            <div style="flex: 1;">材质</div>
            <!-- <div style="flex: 1;">金重(g)</div>
            <div style="flex: 1;">总重</div>
            <div style="flex: 1;">主石重量</div> -->
            <div style="flex: 1;">回购价</div>
          </div>
          <div class="flex" v-for="(item,index) in printData1" :key="index + 'print'">
            <div style="flex: 2;">{{item.goods_number}}</div>
            <div style="flex: 2;">{{item.goods_name}}</div>
            <div style="flex: 1;">{{item.material_name}}</div>
            <!-- <div style="flex: 1;">{{item.goldweight}}</div>
            <div style="flex: 1;">{{item.totalweight}}</div>
            <div style="flex: 1;">{{item.msct}}</div> -->
            <div style="flex: 1;">{{item.recycle_price}}</div>
          </div>
          <!-- <div class="table-header flex">
            <div style="flex: 2;">合计</div>
            <div style="flex: 2;"></div>
            <div style="flex: 1;"></div>
            <div style="flex: 1;">{{sumObj1.goods_pcs}}</div>
            <div style="flex: 1;"></div>
            <div style="flex: 1;"></div>
            <div style="flex: 1;">{{sumObj1.actual_sale_price}}</div>
          </div> -->
        </div>
        <!-- <div class="table" v-if="printData1.length" style="border-top: 1px solid #ddd; margin-top: 10px;">
          <el-table
            :data="printData1"
            style="width: 100%"
            stripe
            :summary-method="getSummaries"
          >
            <template v-for="item in printRowList1">
              <el-table-column
                :key="item.field_alias"
                :prop="item.field_alias"
                :label="item.field_text"
                align="center"
                :width="item.t_width">
                <template slot-scope="scope">
                  {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                </template>
              </el-table-column>
            </template>
          </el-table>
      </div> -->
      <div class="heji">
        <div class="item">
          <span class="left">收款方式：</span>
          <span>{{printData.pay_type}}</span>
        </div>
        <div class="item">
          <span class="left">回收金额合计：</span>
          <span>{{printData.recycle_amount}}</span>
        </div>
      </div>
      <div class="heji">
        <div class="item">
          <span class="left">合计金额(大写)：</span>
          <span>{{printData.recycle_amount_dx}}</span>
        </div>
      </div>
        </div>
      </template>
      <template  v-else>
        <Print :type="40" :order_id="currRow.recycle_order_id" />
      </template>
    </el-dialog>
    <Detail v-if="detailVisible" @onHideDetail="handleHideDetail" :recycle_order_id="currRow.recycle_order_id" />
    <RedeemGoods :dialogVisible="redeemVisible" @onHideRedeem="handleHideRedeem" :order_id="currRow.recycle_order_id" v-if="redeemVisible" />
    <Add v-if="addVisible" @onAddHide="handleHideAdd" :recycle_order_id="currRow.recycle_order_id" :reverse="true" />
  </div>
</template>

<script>
import numeral from 'numeral';
import moment from 'moment';
import { getIsLock } from '@/api/monthSettle';
import { getMyRecycleOrderlistReq as getListReq, getRecycleOrderData as getOrderInfoReq, printRecycleOrderReq, resetRecycleDetailsReq } from '@/api/order/offlineOrder/list';
import Cond from '@/components/cond/Index.vue';
import Detail from './Detail.vue';
import RedeemGoods from './RedeemGoods.vue';
import Print from '@/components/print/Index.vue';
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";
import Add from '@/views/order/offlineOrder/salesOrder/Add';

export default {
  data() {
    return {
      noSockIds: [],
      addVisible: false,
      printLoading: false,
      title: '回收订单',
      localPrintVisible: false,
      printData: {},
      printRows: [], // 打印的行
      printType: 'noCustom',
      sumObj1: {},
      printObj: {
        id: 'printTest',
        popTitle: '回收订单',
      },
      infoLoading: false,
      printVisible: false,
      loading: false,
      redeemVisible: false,
      detailVisible: false,
      currRow: {}, // 当前行
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      cond: {}, // 搜索的配置
      loading3: false,
      multipleSelection: [], // 多选列表
      rowList: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: '回收单号', field_alias: 'recycle_order_number', t_width: 250 },
        { field_text: '原订单号', field_alias: 'order_number', t_width: 250 },
        { field_text: '货品', field_alias: 'goods', t_width: 300 },
        { field_text: '材质成色', field_alias: 'material_name', t_width: 120 },
        { field_text: '数量', field_alias: 'goods_pcs', t_width: 120 },
        { field_text: '净金重', field_alias: 'goldweight', t_width: 120 },
        { field_text: '主销售', field_alias: 'main_store_name', t_width: 120 },
        { field_text: '辅销售', field_alias: 'vice_store_name', t_width: 120 },
        { field_text: '回收金额', field_alias: 'recycle_amount', t_width: 120 },
        { field_text: '会员', field_alias: 'member', t_width: 120 },
        { field_text: '回收时间', field_alias: 'create_time', t_width: 200 },
        { field_text: '所属门店', field_alias: 'hierarchy_name', t_width: 120 },
      ],
      printData: {},
      printRowList1: [
        { field_text: '货号', field_alias: 'goods_number' },
        { field_text: '品类', field_alias: 'goods_type_name' },
        { field_text: '材质', field_alias: 'material_name' },
        { field_text: '金重(g)', field_alias: 'goldweight' },
        { field_text: '总重', field_alias: 'totalweight' },
        { field_text: '主石重量', field_alias: 'msct' },
        { field_text: '回购价', field_alias: 'recycle_price' },
      ],
      printData1: [
      ],
      tableData: {
        list: [],
        total: 0,
      },
      activeArr: [],
    };
  },
  components: {
    Add,
    Cond,
    Detail,
    RedeemGoods,
    Print,
    PrintConf,
    LocalPrint,
  },
  mounted() {
    if (this.$route.params.type) {
      if (this.$route.params.type === 'detail') {
        this.currRow.recycle_order_id = this.$route.params.key;
        this.detailVisible = true;
      }
    }
  },
  activated() {
    this.getList();
  },
  methods: {
    handleHideAdd(getList) {
      this.addVisible = false;
      this.currRow.select = '';
      this.currRow = {};
      if (getList) {
        this.getList();
      }
    },
    handleSetCond(cond) {
      this.cond = { ...cond };
    },
    handlePrint() {
      this.getRecoveryPrint({ ...this.cond });
    },
    getRecoveryPrint(cond) {
      this.printLoading = true;
      getRecoveryPrintReq({ ...cond })
        .then((res) => {
          if (res.code === 1) {
            this.printLoading = false;
            this.currRow.select = '';
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            res.data.top = [];
            const data = res.data.data;
            const arr1 = [
              { txt: '打印时间', value: data.print_time, width: '24%' },
              { txt: '门店', value: data.hierarchy_name, width: '24%' },
            ];
            res.data.bottom = [];
            res.data.top.push(arr1);
            // 计算合计
            this.printData = res.data;
            const sum = {
              index: '合计',
            };
            const decimal = {};
            const newArr = [];
            this.printData.list.forEach((item) => {
              item.goods.forEach((good, goodIndex) => {
                const newItem = { ...item, ...good };
                if (goodIndex === 0) {
                  newItem.rowspan = item.goods.length;
                }
                newArr.push(newItem);
              });
              this.printRows.forEach((row) => {
                if (row.is_sum) {
                  // 不需要合并的
                  if (!row.is_goods) {
                    if (!sum[row.field_alias]) {
                      sum[row.field_alias] = 0;
                    }
                    if (item[row.field_alias]) {
                      sum[row.field_alias] += Number(item[row.field_alias]);
                      if (!decimal[row.field_alias]) {
                        const arr = String(item[row.field_alias]).split('.');
                        if (arr.length === 2) {
                          decimal[row.field_alias] = arr[1].length;
                        } else {
                          decimal[row.field_alias] = 0;
                        }
                      }
                    }
                  } else {
                    // 需要合并的
                    if (!sum[row.field_alias]) {
                      sum[row.field_alias] = 0;
                    }
                    item.goods.forEach((good) => {
                      if (good[row.field_alias]) {
                        sum[row.field_alias] += Number(good[row.field_alias]);
                        if (!decimal[row.field_alias]) {
                          const arr = String(good[row.field_alias]).split('.');
                          if (arr.length === 2) {
                            decimal[row.field_alias] = arr[1].length;
                          } else {
                            decimal[row.field_alias] = 0;
                          }
                        }
                      }
                    });
                  }
                }
              });
            });
            // 需要保留多少位小数
            Object.keys(sum).forEach((item) => {
              if (item !== 'index') {
                sum[item] = sum[item].toFixed(decimal[item]);
              }
            });
            this.printData.list = newArr;
            this.printData.list.push(sum);
            this.localPrintVisible = true;
          }
        })
        .catch(() => {
          this.printLoading = false;
          this.currRow.select = '';
        });
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    handleDalclick(row) {
      this.currRow = row;
      // 详情
      this.detailVisible = true;
      setTimeout(() => {
        this.currRow.select = '';
      }, 100);
    },
    cellMouseLeave() {
      this.activeArr = [];
    },
    tableRowClassName({ rowIndex }) {
      const arr = this.activeArr;
      for (var i = 0; i < arr.length; i ++) {
        if (rowIndex === arr[i]) {
          return 'success-row';
        }
      }
    },
    cellMouseEnter(row) {
      this.activeArr = row.activeArr;
    },
    getSums() {
      const obj = {
        goods_pcs: 0,
        actual_sale_price: 0,
      };
      this.printData1.forEach((item) => {
        obj.goods_pcs += Number(item.goods_pcs);
        obj.actual_sale_price += Number(item.actual_sale_price);
      });
      obj.actual_sale_price = numeral(obj.actual_sale_price).format('0.00');
      this.sumObj1 = obj;
    },
    yunPrint() {
      printRecycleOrderReq({
        recycle_order_id: this.currRow.recycle_order_id,
      })
        .then((res) => {
          this.$notify({
            title: '云打印',
            message: res.return_data,
          });
        });
    },
    getSummaries(param) {
      const { data } = param;
      const sums = ['合计'];
      sums[3] = 0;
      sums[6] = 0;
      data.forEach((item) => {
        sums[3] += Number(item.goods_pcs);
        sums[6] += Number(item.actual_sale_price);
      })
      sums[6] = numeral(sums[6]).format('0.00');
      return sums;
    },
    handleClose() {
      this.currRow.select = '';
      this.currRow = {};
      this.printData = {};
      this.printVisible = false;
      this.printType = 'noCustom';
    },
    handleHideRedeem(getList) {
      this.currRow.select = '';
      this.redeemVisible = false;
      if (getList) {
        this.page = 1;
        this.getList();
      }
    },
    // 改变分页条数
    handleSizeChange(page_num) {
      this.page_num = page_num;
      this.getList();
    },
    getList() {
      this.loading = true;
      getListReq({ ...this.cond, page: this.page, page_num: this.page_num })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            const { data } = res;
            data.order_amount = numeral(data.order_amount).format('0.00');
            data.order_discount = numeral(data.order_discount).format('0.00');
            data.paid_in_amount = numeral(data.paid_in_amount).format('0.00');
            // 处理数据
            // const newArr = [];
            // let rowIndex = 0;
            // data.recycle_amount = numeral(data.recycle_amount).format('0.00');
            // data.list.forEach((item, index) => {
            //   if (index > 0) {
            //     rowIndex += data.list[index - 1].goods.length;
            //   }
            //   item.index = index + 1;
            //   const activeArr = [];
            //   // item.create_time = moment(item.create_time * 1000).format('YYYY-MM-DD HH:mm:ss');
            //   item.goods.forEach((good, goodIndex) => {
            //     const realIndex = rowIndex + goodIndex;
            //     const obj = { ...good, ...item };
            //     if (goodIndex === 0) {
            //       obj.span = item.goods.length;
            //     }
            //     activeArr.push(realIndex);
            //     obj.activeArr = activeArr;
            //     newArr.push(obj);
            //   });
            // });
            // this.tableData = {
            //   ...res.data,
            //   list: newArr,
            // };
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
              item.material_name = [];
              item.goods_pcs = [];
              item.goldweight = [];
              item.goods.forEach((good) => {
                item.material_name.push(good.material_name);
                item.goods_pcs.push(good.goods_pcs);
                item.goldweight.push(good.goldweight);
              });
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleHideDetail() {
      this.detailVisible = false;
    },
    handleHidePickUp() {
      this.pickUpVisible = false;
    },
    // 新增模块隐藏事件
    handleAddHide() {
      this.dialogVisible = false;
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    /*
     *  导出
     */
    handleExport() {
      this.loading3 = true;
      // getListReq({ ...this.cond, page: this.page, page_num: this.page_num, export: 2, function_code: 'orderList' })
      recycleOrderExportExcelReq()
        .then((res) => {
          this.loading3 = false;
          res = "\ufeff" + res;
          var blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const time = new Date().getTime();
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, `回收订单列表${time}`);
          } else {
            var urlCreator = window.URL || window.wibkitURL;
            var url = urlCreator.createObjectURL(blob);
            var a = document.createElement("a");
            a.download = `回收订单列表${time}`;
            a.href = url;
            a.click();
          }
        })
        .catch(() => {
          this.loading3 = false;
        });
    },
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    handleCond(cond) {
      this.page = 1;
      this.cond = { ...cond };
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 获取订单详情
    // getOrderInfo(order_id) {
    //   getOrderInfoReq(order_id)
    //     .then((res) => {
    //       if (res.code === 1) {
    //         console.log(res.data);
    //       }
    //     });
    // },
    // 操作选择触发
    handleChange(e, row) {
      this.currRow = row;
      // 详情
      if (e === 1) {
        this.detailVisible = true;
        setTimeout(() => {
          this.currRow.select = '';
        }, 100);
      }
      // 云打印
      if (e === 2) {
        this.printVisible = true;
        this.infoLoading = true;
        getOrderInfoReq(this.currRow.recycle_order_id)
          .then((res) => {
            this.infoLoading = false;
            if (res.code === 1) {
              this.printData = res.data;
              this.printData1 = res.data.goods;
            }
          })
          .catch(() => {
            this.infoLoading = false;
            this.loading = false;
          });
      }
      // 赎回
      if (e === 3) {
        this.redeemVisible = true;
      }
      // 解锁
      if (e === 4) {
        getIsLock({
          source: 'recycle',
          primary_key_id: row.recycle_order_id,
        })
          .then((res) => {
            if (res.code === 1) {
              // this.addVisiable = true;
              this.$message.success('解锁成功');
              row.select = '';
              this.currRow = {};
              this.noSockIds.push(row.recycle_order_id);
            }
          })
          .catch(() => {
            row.select = '';
            this.currRow = {};
          });
      }
      // 编辑
      if (e === 5) {
        // this.addVisible = true;
        resetRecycleDetailsReq({
          recycle_order_id: row.recycle_order_id,
        })
          .then((res) => {
            if (res.code === 1) {
              this.addVisible = true;
            }
          })
          .catch(() => {
            row.select = '';
          });
      };
      // 删除
      if (e === 6) {
        this.$confirm('确定要删除该回收单吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            row.select = '';
            recoverDeleReq({
              recycle_order_id: row.recycle_order_id,
            })
              .then((res) => {
                if (res.code === 1) {
                  this.$message.success('删除成功');
                  this.getList();
                }
              })

          })
          .catch(() => {
            row.select = '';
          })

      }
    },
    objectSpanMethod({ row, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 6 || columnIndex === 7 || columnIndex === 8 || columnIndex === 9 || columnIndex === 10 || columnIndex === 11) {
        if (row.span) {
          return {
            rowspan: row.span,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        };
      }
    },
  },
};
</script>;

<style lang="less" scoped>
.border {
  border-bottom: 1px solid #ddd;
}
/deep/ .el-table__row .cell {
  padding: 0;
}

 /deep/.el-table .success-row {
  background: #f5f7fa;
}

.my-table {
  line-height: 42px;
  .table-header {
    background: #eee;
  }
  .flex {
    display: flex;
    div {
      text-align: center;
      font-weight: bold;
    }
  }
}
.heji {
  display: flex;
  line-height: 40px;
  font-size: 14px;
  justify-content: flex-end;
  .item {
    margin-right: 20px;
    flex-direction: end;
    .left {
      font-weight: bold;
    }
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 50%;
    display: flex;
    font-size: 14px;
    line-height: 30px;
    .left {
      width: 120px;
      font-weight: bold;
    }
    .right {
      flex: 1;
    }
  }
}
.title {
  margin-top: 20px;
  font-weight: bold;
  line-height: 40px;
  font-size: 16px;
}
.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  // padding: 0 20px;
  // background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: white;
  margin-bottom: 10px;
  border-radius: 0px 0px 6px 6px;
  // border-radius: 0px 0px 6px 6px;
  &-left {
  }
  &-right {
    display: flex;
    flex: 1;
    &-item {
      font-size: 15px;
      // padding: 0 10px;
      width: 15%;
      // border-right: 1px solid #ddd;
      text-align: center;
      color: #909399;
      span {
        font-size: 16px;
        color: #E7541E;
      }
    }
  }
}
.label {
  text-align: left;
  color: white;
  background: #E7541E;
  width: 38px;
  line-height: 16px;
  padding: 2px 5px;
  box-sizing: border-box;
  border-radius: 3px;
  margin-top: 5px;
}
</style>
