<template>
  <div>
    <div style="background: white;" v-if="!visiable">
      <div class="card">
        <el-page-header @back="goBack" content="回收订单详情">
        </el-page-header>
      </div>
      <div class="list">
        <div class="item">
          <div class="left">回收订单号</div>
          <div>{{orderInfo.recycle_order_number || '--'}}</div>
        </div>
        <div class="item">
          <div class="left">原订单号</div>
          <div>{{orderInfo.order_number || '--'}}</div>
        </div>
        <div class="item">
          <div class="left">会员姓名</div>
          <div>{{orderInfo.member_name || '--'}}</div>
        </div>
        <div class="item">
          <div class="left">会员电话</div>
          <div>{{orderInfo.member_phone || '--'}}</div>
        </div>
        <div class="item">
          <div class="left">会员等级</div>
          <span>{{orderInfo.member_level || '--'}}</span>
          <img :src="orderInfo.level_Img"
                v-if="orderInfo.level_Img"
              style="width: auto; height: 20PX; margin-left: 10px;"
          />
        </div>
        <div class="item">
          <div class="left">主销售员</div>
          <div>{{orderInfo.main_store_name || '--'}}</div>
        </div>
        <div class="item">
          <div class="left">辅销售员</div>
          <div>{{orderInfo.vice_store_name || '--'}}</div>
        </div>
        <div class="item">
          <div class="left">回收时间</div>
          <div>{{orderInfo.create_time || '--'}}</div>
        </div>
        <div class="item">
          <div class="left">回收金额</div>
          <div>{{orderInfo.recycle_amount || '--'}}</div>
        </div>
        <div class="item">
          <div class="left">制单人</div>
          <div>{{orderInfo.store_user_name || '--'}}</div>
        </div>
        <div class="item">
          <div class="left">备注</div>
          <div>{{orderInfo.remark || '--'}}</div>
        </div>
      </div>
      <div class="tabs">
        <el-tabs v-model="activeName">
        <el-tab-pane label="货品明细" name="first">
          <div class="table">
            <el-table
              :data="goodList"
              style="width: 100%"
              @row-dblclick="toGoodDetail"
            >
              <template v-for="item in rowList">
                <el-table-column
                  :key="item.field_alias"
                  :prop="item.field_alias"
                  :label="item.field_text"
                  align="center">
                  <template slot-scope="scope">
                    <template v-if="item.field_alias === 'goods_pic'">
                      <el-image :src="scope.row[item.field_alias]"  :preview-src-list="[scope.row[item.field_alias]]" v-if="scope.row[item.field_alias]" style="width: 54px; height: 54px; margin-top: 5px; object-fit: contain;" />
                      <img v-else src="../../../../assets/images/no_img.png" style="width: 54px; height: 54px; margin-top: 5px" />
                    </template>
                    <template v-else-if="item.field_alias === 'goods_name'">
                      <div style="display: flex; align-items: center; justify-content: center;">
                        {{scope.row.goods_name}}
                        <div style="display: flex; margin-left: 5px;">
                          <template v-for="item in scope.row.goods_tag_pic">
                            <img :src="item" :key="item" style="margin-right: 5px;" />
                          </template>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      {{(scope.row[item.field_alias] || scope.row[item.field_alias]  === 0)? scope.row[item.field_alias] : '--'}}
                    </template>
                  </template>
                </el-table-column>
              </template>
              <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <el-button type="text" @click="toGoodDetail(scope.row)">详情</el-button>
                  </template>
                </el-table-column>
              <!-- <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="text">详情</el-button>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
          <!-- <div class="order">
            <div style="margin-bottom: 10px; font-weight: bold;">订单金额信息</div>
            <div>
              <span>订单金额</span>
              <span>￥10.00</span>
            </div>
            <div>
              <span>旧料金额</span>
              <span>￥10.00</span>
            </div>
            <div>
              <span>预存金额</span>
              <span>￥10.00</span>
            </div>
            <div>
              <span>优惠券折扣金额</span>
              <span>￥10.00</span>
            </div>
            <div>
              <span>积分抵扣金额</span>
              <span>￥10.00</span>
            </div>
            <div>
              <span>抹零金额</span>
              <span>￥10.00</span>
            </div>
            <div>
              <span>会员折扣金额</span>
              <span>￥10.00</span>
            </div>
            <el-divider></el-divider>
            <div>
              <span>实收金额</span>
              <span style="color: #E7541E;">￥10.00</span>
            </div>
          </div> -->
        </el-tab-pane>
        <el-tab-pane label="调换记录" name="second" v-if="isChange">
          <div class="wrapper">
            <div class="table" style="width: 49.5%;">
              <div class="title">调换前的货品</div>
              <el-table
                :data="beforeData"
                style="width: 100%"
              >
                <template v-for="item in exchangeRowList">
                  <el-table-column
                    :key="item.field_alias"
                    :prop="item.field_alias"
                    :label="item.field_text"
                    align="center">
                    <template slot-scope="scope">
                      {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                    </template>
                  </el-table-column>
                </template>
              </el-table>
            </div>
            <div class="table" style="width: 49.5%;">
              <div class="title">调换后的货品</div>
              <el-table
                :data="afterData"
                style="width: 100%"
              >
                <template v-for="item in exchangeRowList">
                  <el-table-column
                    :key="item.field_alias"
                    :prop="item.field_alias"
                    :label="item.field_text"
                    align="center">
                    <template slot-scope="scope">
                      {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                    </template>
                  </el-table-column>
                </template>
              </el-table>
            </div>
          </div>
          <div class="order">
            <div style="margin-bottom: 10px; font-weight: bold;">订单金额信息</div>
            <div>
              <span>原订单实收金额</span>
              <span>￥10.00</span>
            </div>
            <div>
              <span>调换后的订单金额</span>
              <span>￥10.00</span>
            </div>
            <el-divider></el-divider>
            <div>
              <span>补差价</span>
              <span style="color: #E7541E;">￥10.00</span>
            </div>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="操作日志" name="third">
          <div class="table">
            <el-table
              :data="logData"
              style="width: 100%"
            >
              <template v-for="item in logRowList">
                <el-table-column
                  :key="item.field_alias"
                  :prop="item.field_alias"
                  :label="item.field_text"
                  align="center">
                  <template slot-scope="scope">
                    {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </div>
        </el-tab-pane> -->
      </el-tabs>
      </div>
    </div>
    <Details v-if="visiable" :goods_id="goods_id" @hide="visiable = false" :recycle_order_id="recycle_order_id" />
  </div>
</template>

<script>
import moment from 'moment';
import { getRecycleOrderData as getDetailReq } from '@/api/order/offlineOrder/list';
import Details from "@/views/goods/goodsStock/goodsDetailedlist/details/Index";

export default {
  data() {
    return {
      recycle_order_id: '',
      visiable: false,
      goodList: [], // 货品信息
      orderInfo: {}, // 订单信息
      activeName: 'first',
      rowList: [
        { field_text: '货品图片', field_alias: 'goods_pic' },
        { field_text: '货号', field_alias: 'goods_number' },
        { field_text: '品名', field_alias: 'goods_name' },
        { field_text: '品类', field_alias: 'goods_type_name' },
        // { field_text: '货品类型', field_alias: 'goods_number_type_name' },
        // { field_text: '品牌', field_alias: '103' },
        { field_text: '材质成色', field_alias: 'material_name' },
        { field_text: '金重(毛)', field_alias: '1446' },
        { field_text: '净金重', field_alias: 'goldweight' },
        { field_text: '仓库', field_alias: 'warehouse_name' },
        { field_text: '数量', field_alias: 'goods_pcs' },
        { field_text: '回购单价', field_alias: 'recycle_price' },
        { field_text: '折旧费(元/克)', field_alias: '114' },
        { field_text: '补金单价(元/克)', field_alias: '1450' },
      ],
      exchangeRowList: [
        { field_text: '货品图片', field_alias: 'img' },
        { field_text: '品名', field_alias: 'img2' },
        { field_text: '货号', field_alias: 'img3' },
        { field_text: '品类', field_alias: 'img4' },
        { field_text: '标签价', field_alias: 'img5' },
      ],
      logRowList: [
        { field_text: '操作时间', field_alias: 'img' },
        { field_text: '操作记录', field_alias: 'img2' },
        { field_text: '操作人', field_alias: 'img3' },
      ],
      beforeData: [],
      afterData: [],
      logData: [],
    };
  },
  props: {
    recycle_order_id: {
      type: Number,
      default: 412,
    },
    isChange: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Details,
  },
  created() {
    // this.getBasicInfo();
    this.getDetail();
  },
  methods: {
    toGoodDetail(row) {
      this.goods_id = row.goods_id;
      this.recycle_order_id = this.orderInfo.recycle_order_id;
      this.visiable = true;
    },
    // 退货货品明细
    getDetail() {
      getDetailReq(this.recycle_order_id)
        .then((res) => {
          if (res.code === 1) {
            this.orderInfo = res.data;
            this.goodList = res.data.goodsIdList;
          }
        });
    },
    // 获取退货单基本信息
    getBasicInfo() {
      getBasicInfoReq(this.recycle_order_id)
        .then((res) => {
          if (res.code === 1) {
            res.data.create_time = moment(res.data.create_time * 1000).format('YYYY-MM-DD HH:mm:ss');
            this.orderInfo = res.data;
          }
        });
    },
    goBack() {
      this.$emit('onHideDetail');
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  justify-content: space-between;
  .table {
    .title {
      line-height: 50px;
      text-indent: 20px;
      background: white;
      margin-top: 10px;
      font-weight: bold;
    }
  }
}
.order {
  width: 100%;
  margin-top: 10px;
  margin-right: 30px;
  padding: 30px;
  box-sizing: border-box;
  background: white;
  div {
    margin-left: auto;
    width: 350px;
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    span:nth-child(1) {
      color: #909399;
    }
  }
}
/deep/ .el-tabs__header {
  margin: 0 !important;
  padding-left: 50px;
}
/deep/ .tabs {
  padding: 0 !important;
}
/deep/ .el-tabs__content {
  background: #ddd;
}
/deep/ .el-tabs__item {
  font-size: 16px;
  height: 60px;
  line-height: 60px;
}
.list {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 50px 10px 50px;
  margin-top: 20px;
  .item {
    width: 25%;
    display: flex;
    margin-bottom: 20px;
    .red {
      color: #E7541E;
      font-size: 12px;
      margin-left: 10px;
      margin-top: 3px;
    }
    .left::before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #2791FF;
        position: absolute;
        top: 10px;
        left: -15px;
      }
    div:nth-child(1) {
      width: 100px;
      color: #909399;
      position: relative;
    }
    div:nth-child(2) {
      color: #565656;
    }
  }
}
</style>
